:local(.component) {
    position: relative;
    width: 100%;

    :global {
        figure.image {
            width: 100px;
            height: 100px;
            border: 1px solid #dddddd;
            
            img {
                position: relative;
                width: 100px;
                height: 100px;
                object-fit: cover;
            }

            div.mask {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: black;
                opacity: 0.1;
            }

            i {
                position: absolute;
                top: 4px;
                right: 4px;
                color: #ddd;

                &.selected {
                    color: white;
                }
            }
        }

        div.media-content {
            padding-left: 10px;
            max-width: calc(100% - 100px);
            height: 100px;
            overflow-y: hidden;

            div.content {
                display: table;
                height: 100px;
                p {
                    display: table-cell;
                    font-size: 14px;
                    vertical-align: middle;
                }
            }
        }

        div.dropdown {
            div.dropdown-menu {
                width: 168px;
                min-width: 168px;
                top: calc(98px / 2 + 10px);
            }
        }

        div.color-tag {
            &.absolute {
                position: absolute;
                top: calc(98px / 2 - 22px);
                right: -10px;
                padding: 10px;
            }

            &.inline-block {
                position: relative;
                display: inline-block;
                padding: 10px;
            }

            div.value {
                border-radius: 4px;
                width: 14px;
                height: 14px;
                display: inline-block;

                &.red {
                    background-color: red;
                }
                &.blue {
                    background-color: blue;
                }
                &.green {
                    background-color: green;
                }
                &.black {
                    background-color: black;
                }
                &.yellow {
                    background-color: yellow;
                }
                &.purple {
                    background-color: purple;
                }
                &.navy {
                    background-color: navy;
                }
                &.gray {
                    background-color: grey;
                }
            }
        }

        
        div.delete-button {
            position: absolute;
            top: -5px;
            // bottom: 14px;
            right: -10px;
            padding: 2px;
            width: 34px;
            height: 34px;

            i {
                position: relative;
                height: 30px;
                width: 30px;
                line-height: 30px;
                text-align: center;
                border-radius: 15px;
                border: 1px solid black;
                transform: scale(0.5);
            }
        }

        button.loading {
            position: absolute;
            top: calc(50% - 20px);
            left: calc(50% - 20px);
            width: 40px;
            height: 40px;
        }

        div.prepare-message {
            position: absolute;
            top: calc(50% + 16px);
            left: 0;
            right: 0;
            color: #dddddd;
            text-align: center;
            font-size: 12px;
        }

        div.video-message {
            position: relative;
            display: inline-block;
            margin-left: 10px;
            text-align: center;
            color: #888888;
        }

        div.duration {
            position: relative;
            width: 100px;
            text-align: center;
            color: #888888;
        }
    }
}
:local(.single_select_page) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: white;

    :global {
        button.navbar-burger {
            margin: 10px 10px 10px auto;
            height: 30px;
        }
    }
}

:local(.page) {
  width: 100%;
  height: 100%;
  background-color: #353f4f;

  :global {
    img.background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
    div.mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      height: 100%;
      width: 100%;
    }

    button.top-image {
      position: absolute;
      background-color: transparent;
      top: 10vh;
      left: 7vw;
    }
    div.title {
      position: absolute;
      display: table;
      top: 20vh;
      left: 7vw;

      label {
        display: table-cell;
        vertical-align: middle;

        p {
          margin-bottom: 0;
        }
      }

      span.title-edit {
        display: table-cell;
        vertical-align: middle;
        padding: 0 0 0 16px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }

    div.menu {
      position: absolute;
      width: 100%;
      top: 38vh;

      div.normal {
        margin-top: 20px;
        button {
          width: 65%;
          height: 55px;
          font-size: 16px;
        }
      }
      div.release {
        margin-top: 70px;
        button {
          width: 60%;
          height: 55px;
          font-size: 14px;
        }
      }
    }
  }
}

:local(.component) {
  width: 100%;
  height: 100%;

  :global {
    div.copy-delete {
      text-align: right;
      button {
        background-color: transparent;
        margin: 10px;
      }
    }
    div.detail {
      position: absolute;
      top: 46vh;
      padding: 10vw;
      width: 100%;

      img {
        width: 64px;
        height: 64px;
        padding: 8px;
      }

      div.columns {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

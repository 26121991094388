:local(.component) {
    position: relative;
    width: 100%;
    height: 100%;

    :global {
        div.modal {
            div.modal-content {
                max-height: 100vh;

                video.background-video {
                    position: relative;
                    width: 100vw;
                    height: 100vh;
                    object-fit: cover;
                    overflow: hidden;
                }

                div.black-mask {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: linear-gradient(
                      to bottom,
                      rgba(#000000, 0.3),
                      rgba(#000000, 0.0) 30%
                    );
                }

                div.replay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: rgba(#000000, 0.3);

                    img {
                        position: absolute;
                        top: calc(50% - 70px);
                        left: calc(50% - 70px);
                        padding: 50px;
                        width: 140px;
                        height: 140px;
                        object-fit: contain;
                    }
                }
            }
        }
    }
}
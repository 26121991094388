:local(.component) {
  width: 100%;

  :global {
    div.copy-delete {
      text-align: right;
      button {
        background-color: transparent;
        margin: 10px;
      }
    }
    div.detail {
      position: absolute;
      top: 46vh;
      padding: 10vw;
      width: 100%;

      img {
        width: 64px;
        height: 64px;
        padding: 8px;
      }

      div.columns {
        margin-top: 20px;
        text-align: center;
      }
    }
  }
}

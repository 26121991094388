:local(.page) {
  height: 100%;
  :global {
    div.header {
      position: relative;
      border-bottom: 1px rgba(128, 128, 128, 0.7);
      border-bottom-style: solid;
      padding-bottom: 14px;

      div.columns {
        padding: 24px 14px 0 24px;
        label {
          position: absolute;
          bottom: 14px;
          font-size: 18px;
        }

        div.btn-list {
          text-align: right;
          button {
            font-size: 11px;
            width: 70px;
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }
    }
    ul {
      margin: 16px 12px;
    }
  }
}

:local(.chapter) {
  width: 100%;
  :global {
    div.before-id {
      height: 28px;
    }
    div.contents {
      position: relative;
      height: 60px;
      background-color: lightgray;

      div.thumbnail {
        position: relative;
        width: 60px;
        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
        div.black-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          background-color: #353f4f;
        }
      }

      div.chapter-id {
        position: relative;
        width: 60px;
        line-height: 60px;
        text-align: center;
        font-weight: bold;
        font-size: 17px;

        border-right: 2px rgba(255, 255, 255, 1);
        border-right-style: solid;
      }

      div.action-text {
        position: relative;
        text-align: center;
        display: table;
        height: 100%;
        max-width: 65%;
        label {
          padding: 5px;
          display: table-cell;
          vertical-align: middle;
        }
      }
    }
    div.after-id {
      height: 28px;
    }
  }
}

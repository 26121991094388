:local(.select) {
  width: 100%;
  // border: 1px dashed rgba(128, 128, 128, 0.55);
  // border-radius: 9px;
  // padding: 1.2em;
  margin-top: 1em;
  :global {
    div.before-id {
      height: 28px;
    }
    div.contents {
      height: 60px;
      background-color: lightgray;
    }
    div.after-id {
      height: 28px;
    }
    header.card-header {
      background-color: #3db599;
    }
  }
}

:local(.component) {
  width: 100%;

  :global {
    div.menu {
      position: absolute;
      width: 100%;
      top: 38vh;

      div.normal {
        margin-top: 20px;
        button {
          width: 65%;
          height: 55px;
          font-size: 16px;
        }
      }
      div.release {
        margin-top: 70px;
        button {
          width: 60%;
          height: 55px;
          font-size: 14px;
        }
      }
    }
  }
}

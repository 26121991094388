:local(.page) {
  :global {
    div.header {
      padding: 16px;
      border-bottom: 1px rgba(128, 128, 128, 0.7);
      border-bottom-style: solid;
    }

    div.contents {
      position: relative;
      height: 124px;
      border-bottom: 1px rgba(128, 128, 128, 0.7);
      border-bottom-style: solid;
      div.thumbnail {
        position: relative;
        width: 35%;
        div.black-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 100px;
          height: 100px;
          background-color: #353f4f;
        }
        img {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
      }
      div.details {
        padding: 20px 0 10px 0;
        label.published {
          position: absolute;
          top: 5px;
          right: 10px;
          font-size: 12;
          font-size: 11px;
          color: white;
          background-color: #3db599;
          padding: 2px 10px;
        }

        label.story-title {
          font-size: 14px;
        }
      }
    }
  }
}

:local(.loding) {
  position: relative;
  width: 100%;
  height: 100%;

  :global {
    div.modal-content {
      text-align: center;
    }
  }
}

:local(.page) {
  position: relative;
  // height: 100%;

  :global {
    div.header {
      position: relative;
      border-bottom: 1px rgba(128, 128, 128, 0.7);
      border-bottom-style: solid;
      padding-bottom: 14px;

      div.columns {
        padding: 24px 14px 0 24px;
        label {
          position: absolute;
          bottom: 14px;
          font-size: 18px;
        }

        div.btn-list {
          text-align: right;
          button {
            font-size: 11px;
            width: 70px;
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }
    }

    div.container {
      padding: 2em 1.5em;
    }
    div.invalid {
      margin: 0px 0px 10px 0;
    }

    div.video-select {
      position: relative;
      width: 110px;
      height: 110px;
      margin-bottom: 20px;

      img {
        position: relative;
        width: 110px;
        height: 110px;
        object-fit: cover;
      }

      figure {
        position: relative;
        width: 110px;
        height: 110px;
        border: 1px solid #ddd;

        i {
          position: absolute;
          top: calc(50% - 20px);
          left: 0;
          right: 0;
          line-height: 40px;
          text-align: center;
        }

        button.loading {
          position: absolute;
          top: calc(50% - 20px);
          left: calc(50% - 20px);
          width: 40px;
          height: 40px;
        }
      }
    }

    div.chapter-id {
      text-align: right;
    }
    ul {
      margin: 16px 12px;
    }

    div.input-text {
      margin-bottom: 24px;
      label {
        font-size: 10px;
        width: 100%;
      }
    }

    div.video-select-modal {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      max-height: 100vh;
      z-index: 100;
    }
  }
}

:local(.select) {
  width: 100%;
  padding: 24px 0;
  :global {
    div.before-id {
      height: 28px;
    }
    div.contents {
      height: 60px;
      background-color: lightgray;
    }
    div.after-id {
      height: 28px;
    }
  }
}

:local(.keyword) {
  width: 100%;
  padding: 24px 0;
  :global {
    div.before-id {
      height: 28px;
    }
    div.contents {
      height: 60px;
      background-color: lightgray;
    }
    div.after-id {
      height: 28px;
    }
  }
}

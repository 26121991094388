:local(.page) {
  height: 100%;
  :global {
    div.header {
      position: relative;
      border-bottom: 1px rgba(128, 128, 128, 0.7);
      border-bottom-style: solid;
      padding-bottom: 14px;

      div.columns {
        padding: 24px 14px 0 24px;
        label {
          position: absolute;
          bottom: 14px;
          font-size: 18px;
        }

        div.btn-list {
          text-align: right;
          button {
            font-size: 11px;
            width: 70px;
            font-weight: bold;
            margin-left: 10px;
          }
        }
      }
    }
    div.invalid {
      margin: 0px 0px 10px 0;
    }
    div.price {
      ul {
        margin-bottom: 24px;
        li {
          margin-bottom: 20px;
          label {
            display: inline-block;
            width: 150px;
          }
        }
      }
    }

    div.score,
    div.title-visible {
      div.score-radio {
        margin-bottom: 24px;

        label.caution {
          margin-top: 10px;
          display: block;
          font-size: 10px;
          width: 100%;
        }
      }
      label {
        display: inline-block;
        width: 150px;
      }
      div.input-text {
        margin-bottom: 24px;
        label {
          font-size: 10px;
          width: 100%;
        }
      }
    }

    div.id-select {
      margin-bottom: 24px;
      label.caution {
        margin-top: 5px;
        display: block;
        font-size: 10px;
        width: 100%;
      }
    }

    span.radio-span {
      font-size: 18px;
    }

    .radio-input {
      display: none;
    }

    .radio-span {
      padding-left: 37px;
      position: relative;
    }

    .radio-span::before {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background-color: lightgray;
      border-radius: 50%;
    }

    .radio-input:checked + .radio-span::after {
      content: "";
      display: block;
      position: absolute;
      top: -5px;
      left: 0;
      width: 30px;
      height: 30px;
      border-radius: 4px;
      background-color: #3db599;
      border-radius: 50%;
    }
  }
}

div.video-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;

  video.background-video {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 1);
  }

  div.black-mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(#000000, 0.3),
      rgba(#000000, 0.0) 50%,
      rgba(#000000, 0.3)
    );
  }

  div.control-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    div.control-panel {
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: calc(50% - 45px);
        left: calc(50% - 45px);
        width: 90px;
        height: 90px;
      }
    }

    div.skip-button {
      position: absolute;
      right: 4vw;
      bottom: 4vh;

      img {
        width: 44px;
        padding: 3px;
      }
    }
  }

  div.loading-panel {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 1);
    div.spinner {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;

      width: 48px;
      height: 48px;
      border: 4px #ddd solid;
      border-top: 4px #2e93e6 solid;
      border-radius: 50%;
      animation: sp-anime 1s infinite linear;
    }
  }

  @keyframes sp-anime {
    100% {
      transform: rotate(360deg);
    }
  }
}

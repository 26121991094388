:local(.chapter) {
  width: 100%;
  :global {
    div.before-id {
      height: 28px;
    }
    div.contents {
      position: relative;
      height: 60px;
      background-color: lightgray;
      margin-bottom: 3px !important;

      div.thumbnail {
        position: relative;
        width: 60px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
        div.black-image {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          background-color: #353f4f;
        }
      }

      div.chapter-info {
        position: absolute;
        top: 0;
        left: 60px;
        right: 0;
        bottom: 0;

        div.chapter-id {
          position: relative;
          display: table;
          width: 60px;
          line-height: 60px;
          text-align: center;
          font-weight: bold;
          font-size: 17px;
  
          border-right: 2px rgba(255, 255, 255, 1);
          border-right-style: solid;
        }
  
        div.action-text {
          position: absolute;
          top: 0;
          left: 60px;
          right: 0;
          bottom: 0;
          display: table;
          height: 60px;

          label {
            position: relative;
            padding: 5px;
            display: table-cell;
            vertical-align: middle;
          }
        }
      }

    }
    div.before-id {
      text-align: left;
      height: 27px;
      label.start {
        background-color: red;
        margin-right: 2px;
        padding: 5px 25px;
        color: white;
      }
      label.id {
        background-color: #353f4f;
        margin-right: 2px;
        padding: 5px 10px;
        color: white;
      }
    }
    div.after-id {
      text-align: right;
      height: 27px;
      label {
        background-color: #353f4f;
        margin-top: 1px;
        margin-left: 2px;
        padding: 5px 10px;
        color: white;
      }
      label.end {
        background-color: #6f2c0c;
      }
      label.warning {
        padding: 5px 18px;
        background-color: #fdb407;
      }
    }
  }
}

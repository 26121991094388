:local(.container) {
    position: relative;
    width: 100%;
    height: 100%;

    :global {
        img.background {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            object-fit: cover;
            height: 100%;
            height: 100%;
            width: 100%;
        }

        div.black-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            background: linear-gradient(
                to bottom,
                rgba(#000000, 0.3),
                rgba(#000000, 0.0) 50%,
                rgba(#000000, 0.3)
            );
        }

        div.storyTitle {
            position: absolute;
            top: 20vh;
            left: 5vw;
        }

        div.story-top-footer {
            position: absolute;
            width: 100%;
            height: fit-content;
            text-align: center;
            bottom: 5vh;

            div.text-caution {
                margin: 5px;
                font-size: 13px;
            }

            button.trial {
                position: relative;
                width: 70%;
                height: 45px;
            }

            div.purchase {
                position: relative;
                font-size: 17px;
                margin-top: 15px;
            }
        }
    }
}

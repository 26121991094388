div.select-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  div.select-list {
    position: absolute;
    top: 70%;
    transform: translateY(-50%);
    text-align: center;
    left: 0;
    right: 0;
    button.select-button {
      position: relative;
      margin-bottom: 25px;
      height: 48px;
      width: 80%;
    }
    div.limit-point-text {
      position: absolute;
      top: 2px;
      right: 5px;
      font-size: 11px;
    }
  }
}

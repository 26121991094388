div.story-page {
    position: relative;
    width: 100%;
    height: 100%;

    div.black-mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color: black, $alpha: 0.6);
    }

    div.story-not-available {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        height: 30px;
        text-align: center;
    }
}
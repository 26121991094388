:local(.page) {
    background: white;

    :global {
        // section {
        //     padding: 48px 0;
        // }
        div.menu-separator {
            margin: 10px -24px;
            border-bottom: 1px solid #ddd;
        }

        ul.menu-list {
            li {
                a {
                    padding: 10px 0;
                }
            }
        }
    }
}

div.keyword-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;

  div.keyword {
    position: absolute;
    top: 50%;
    text-align: center;
    padding: 0 32px;
    left: 0;
    right: 0;
    input.input-keyword {
      position: relative;
      background-color: rgba(255, 255, 255, 1);
    }
    button.button-decision {
      position: relative;
      margin-top: 180px;
      width: 30%;
    }
  }
}
